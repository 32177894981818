<template>
  <b-container fluid>
    <div style="display: flex; margin-bottom: 1rem;">
      <b-img src="rtMRI_logo1.gif" height="128" class="mr-3"></b-img>
      <div>
        <i18n path="description" tag="p">
          <template v-if="isElectron" #introduction_v2>
            <a :href="$t('introduction_v2_in_electron')">{{ $t("introduction_text") }}</a>
          </template>
          <template v-else #introduction_v2>
            <a :href="$t('introduction_v2_in_webapp')" target="introduction">{{ $t("introduction_text") }}</a>
          </template>
          <template v-if="isElectron" #introduction_v1>
            <a :href="$t('introduction_v1_in_electron')">{{ $t("introduction_text") }}</a>
          </template>
          <template v-else #introduction_v1>
            <a :href="$t('introduction_v1_in_webapp')" target="introduction">{{ $t("introduction_text") }}</a>
          </template>
          <template v-if="isElectron" #niisrc>
            <a :href="$t('niisrc_in_electron')">{{ $t("niisrc_text") }}</a>
          </template>
          <template v-else #niisrc>
            <a :href="$t('niisrc_in_webapp')" target="niisrc">{{ $t("niisrc_text") }}</a>
          </template>
          <template #br><br /></template>
        </i18n>
        <p v-html="$t('principal_investigator')"></p>
      </div>
    </div>

    <b-form>
      <b-form-group v-for="(item, index) in searchFormItems" :key="index" style="width: fit-content;">
        <b-input-group>
          <b-input-group-prepend>
            <b-btn @click="delSearchFormItem(index)" variant="outline-danger" :disabled="delSearchFormItemBtn.disabled"><b-icon icon="x"></b-icon></b-btn>
          </b-input-group-prepend>
          <b-select v-model="item.headingSelected">
            <b-select-option v-for="(item, index) in headingOptions" :key="index" :value="item.value" :disabled="item.disabled">
              {{ $t(item.text) }}
            </b-select-option>
          </b-select>
          <b-select v-model="item.operatorSelected" :state="item.operatorState" @change="item.operatorState = null">
            <b-select-option v-for="(item, index) in operatorOptions" :key="index" :value="item.value" :disabled="item.disabled">
              {{ $t(item.text) }}
            </b-select-option>
          </b-select>
          <b-input v-model="item.searchString" :list="'datalist' + index" :placeholder="$t('action.input_search_string')"></b-input>
          <b-datalist :id="'datalist' + index" :options="getDatalist(item)"></b-datalist>
          <b-input-group-append>
            <b-btn @click="addSearchFormItem(index)" variant="outline-success" :disabled="addSearchFormItemBtn.disabled"><b-icon icon="plus"></b-icon></b-btn>
          </b-input-group-append>
          <b-form-invalid-feedback>{{ item.errorMessage }}</b-form-invalid-feedback>
        </b-input-group>
      </b-form-group>
      <b-form-group :description="$t('playback_of_data_with_different_fps')">
        <b-radio-group v-model="fpsSelected">
          <b-radio v-for="(item, index) in fpsOptions" :key="index" :value="item.value" :disabled="item.disabled">
            {{ $t(item.text) }}
          </b-radio>
        </b-radio-group>
      </b-form-group>
      <b-form-group>
        <b-form-row>
          <b-btn class="mr-3" @click="search" variant="primary"><b-icon icon="search"></b-icon></b-btn>
          <b-btn class="mr-3" @click="selectAllRows" variant="outline-primary" :disabled="selectAllRowsBtn.disabled">{{ $t("action.select_all") }}</b-btn>
          <b-btn class="mr-3" @click="clearSelected" variant="outline-primary" :disabled="clearSelectedBtn.disabled">{{ $t("action.clear_selected") }}</b-btn>
          <b-btn class="mr-3" @click="showPlaybackModal" variant="primary" :disabled="isPlaybackDisabled"><b-icon icon="collection-play"></b-icon></b-btn>
          <b-input-group class="mr-3" :prepend="$t('label.margin')" :append="$t('label.seconds')" style="width: fit-content;">
            <b-input
              type="number"
              min="0"
              max="0.5"
              step="0.05"
              v-model="playback.margin"
              :state="playback.marginState"
              @change="playback.marginState = null"
              :disabled="isPlaybackDisabled"
            ></b-input>
          </b-input-group>
          <b-check v-model="playback.withSubtitles" switch inline>{{ $t("label.subtitles") }}</b-check>
          <b-check v-model="playback.withDentition" switch inline>{{ $t("label.dentition") }}</b-check>
        </b-form-row>
      </b-form-group>
    </b-form>

    <b-alert :show="searchAlert.show" :variant="searchAlert.variant">{{ searchAlert.message }}</b-alert>
    <b-alert :show="playbackAlert.show" :variant="playbackAlert.variant">{{ playbackAlert.message }}</b-alert>

    <div v-if="rtmriItems.length">
      <b-row>
        <b-col>
          <b-pagination v-model="currentPage" :total-rows="countRtmriItems" :per-page="perPage"></b-pagination>
        </b-col>
        <b-col>
          <b-form-group class="text-right">
            <label class="mr-3">{{ $t("label.per_page") }}</label>
            <b-select v-model="perPage" :options="perPageOptions" style="width: fit-content;"></b-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        small
        striped
        :fields="rtmriFields"
        :items="rtmriItems"
        :per-page="perPage"
        :current-page="currentPage"
        selectable
        @row-selected="onRowSelected"
        ref="searchResultTable"
        sort-icon-left
      >
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template>
        </template>
        <template #cell(up)="row">
          <b-icon icon="arrow-up-circle" @click="moveRowUp(row.index)"></b-icon>
        </template>
        <template #cell(down)="row">
          <b-icon icon="arrow-down-circle" @click="moveRowDown(row.index)"></b-icon>
        </template>
      </b-table>
    </div>

    <b-modal id="playback-modal" size="lg" hide-header no-close-on-backdrop no-close-on-esc>
      <video :src="playback.videoSrc" id="video" width="100%" controls autoplay playinline></video>
      <template #modal-footer="{ ok }">
        <b-btn class="mr-3" @click="prevFrame" variant="outline-primary"><b-icon icon="arrow-bar-left"></b-icon></b-btn>
        <b-btn class="mr-3" @click="nextFrame" variant="outline-primary"><b-icon icon="arrow-bar-right"></b-icon></b-btn>
        <b-btn @click="ok()">{{ $t("action.close") }}</b-btn>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import i18n from "../i18n";
import axios from "axios";

export default {
  data() {
    return {
      searchFormItems: [{ headingSelected: null, operatorSelected: null, operatorState: null, searchString: "", errorMessage: null }],
      headingOptions: [
        { text: "action.select_heading", value: null, disabled: true },
        { text: "File", value: "file" },
        { text: "Date", value: "date" },
        { text: "Text", value: "text" },
        { text: "Jtext", value: "jtext" },
        { text: "Phoneme", value: "phoneme" },
        { text: "Tag", value: "tag" },
        { text: "Class", value: "clazz" },
        { text: "Slide", value: "slide" },
        { text: "Slide2", value: "slide2" },
        { text: "Ser", value: "ser" },
        { text: "Session", value: "session" },
        { text: "Subject", value: "subject" },
        { text: "Subject Id", value: "subject_id" },
        { text: "Gender", value: "gender" },
        { text: "Birth Year", value: "birth_year" },
        { text: "Birth Place", value: "birth_place" },
        { text: "Dialect", value: "dialect" },
      ],
      operatorOptions: [
        { text: "action.select_operator", value: null, disabled: true },
        { text: "action.equals", value: "equals" },
        { text: "action.does_not_equal", value: "doesNotEqual" },
        { text: "action.begins_with", value: "beginsWith" },
        { text: "action.ends_with", value: "endsWith" },
        { text: "action.contains", value: "contains" },
        { text: "action.does_not_contain", value: "doesNotContain" },
        { text: "action.is_greater_than_or_equal_to", value: "isGreaterThanOrEqualTo" },
        { text: "action.is_less_than_or_equal_to", value: "isLessThanOrEqualTo" },
      ],
      datalist: {},
      delSearchFormItemBtn: { disabled: true },
      addSearchFormItemBtn: { disabled: false },
      fpsSelected: 14,
      fpsOptions: [
        { text: "14 fps", value: 14 },
        { text: "27 fps", value: 27 },
        { text: "label.both", value: null },
      ],
      rtmriFields: [
        "selected",
        { key: "file", sortable: true },
        { key: "start", sortable: true },
        { key: "end", sortable: true },
        { key: "date", sortable: true },
        { key: "fps", sortable: true },
        { key: "text", sortable: true },
        { key: "jtext", sortable: true },
        { key: "phoneme", sortable: true },
        { key: "tag", sortable: true },
        { key: "clazz", sortable: true, label: "Class" },
        { key: "slide", sortable: true },
        { key: "slide2", sortable: true },
        { key: "ser", sortable: true },
        { key: "session", sortable: true },
        { key: "subject", sortable: true },
        { key: "subject_id", sortable: true },
        { key: "gender", sortable: true },
        { key: "birth_year", sortable: true },
        { key: "birth_place", sortable: true },
        { key: "dialect", sortable: true },
        { key: "up", label: "" },
        { key: "down", label: "" },
      ],
      rtmriItems: [],
      rtmriItemsSelected: [],
      perPage: 100,
      perPageOptions: [
        { text: 100, value: 100 },
        { text: 200, value: 200 },
        { text: 300, value: 300 },
        { text: 500, value: 500 },
      ],
      currentPage: 1,
      selectAllRowsBtn: { disabled: true },
      clearSelectedBtn: { disabled: true },
      searchAlert: { show: false, variant: null, message: null },
      playback: { videoSrc: null, margin: 0, marginState: null, withSubtitles: true, withDentition: false },
      playbackAlert: { show: false, variant: null, message: null },
    };
  },
  computed: {
    isElectron() {
      return typeof window.api !== "undefined";
    },
    countRtmriItems() {
      return this.rtmriItems.length;
    },
    isPlaybackDisabled() {
      // 再生対象が選択されていない (length = 0) あるいは、fps が一定でない場合 (length > 1) は再生を無効化する。
      const fpsArray = this.rtmriItemsSelected.map((item) => item.fps).filter(uniq);
      return fpsArray.length != 1;
    },
  },
  mounted: async function() {
    this.headingOptions
      .map((item) => item.value)
      .filter((heading) => heading != null)
      .forEach(async (heading) => {
        if (typeof window.api !== "undefined") {
          this.datalist[heading] = await window.api.getRtmriDatalist(heading);
        } else {
          axios.get(process.env.VUE_APP_RTMRIAPI_URL + "/datalist/" + heading).then((response) => {
            this.datalist[heading] = response.data;
          });
        }
      });
  },
  methods: {
    getDatalist(item) {
      return this.datalist[item.headingSelected];
    },
    delSearchFormItem(index) {
      this.searchFormItems = this.searchFormItems.filter((item, i) => i != index);
      this.delSearchFormItemBtn.disabled = this.searchFormItems.length < 2;
    },
    addSearchFormItem(index) {
      this.searchFormItems.splice(index + 1, 0, { headingSelected: null, operatorSelected: null, operatorState: null, searchString: "", errorMessage: null });
      this.delSearchFormItemBtn.disabled = this.searchFormItems.length < 2;
    },
    async search() {
      // 検索結果および検索結果を操作するボタン、アラート表示を初期化する。
      this.rtmriItems = [];
      this.rtmriItemsSelected = [];
      this.selectAllRowsBtn.disabled = true;
      this.clearSelectedBtn.disabled = true;
      this.searchAlert.show = false;
      this.playbackAlert.show = false;

      const andArray = [];
      this.headingOptions
        .map((item) => item.value)
        .filter((heading) => heading != null)
        .forEach((heading) => {
          const items = this.searchFormItems.filter((item) => item.headingSelected == heading);
          if (items.length > 0) {
            const orArray = [];
            items.forEach((item) => {
              item.operatorState = null;
              item.errorMessage = null;

              let operator;
              let value;
              switch (item.operatorSelected) {
                case null:
                  item.errorMessage = i18n.t("no_operator");
                  break;
                case "equals":
                  operator = " = ";
                  value = item.searchString;
                  break;
                case "doesNotEqual":
                  operator = " <> ";
                  value = item.searchString;
                  break;
                case "beginsWith":
                  operator = " LIKE ";
                  value = item.searchString.replace(/[\\%_]/g, "\\$&") + "%";
                  break;
                case "endsWith":
                  operator = " LIKE ";
                  value = "%" + item.searchString.replace(/[\\%_]/g, "\\$&");
                  break;
                case "contains":
                  operator = " LIKE ";
                  value = "%" + item.searchString.replace(/[\\%_]/g, "\\$&") + "%";
                  break;
                case "doesNotContain":
                  operator = " NOT LIKE ";
                  value = "%" + item.searchString.replace(/[\\%_]/g, "\\$&") + "%";
                  break;
                case "isGreaterThanOrEqualTo":
                  operator = " >= ";
                  value = item.searchString;
                  break;
                case "isLessThanOrEqualTo":
                  operator = " <= ";
                  value = item.searchString;
                  break;
                default:
                  item.errorMessage = i18n.t("unsupported_operator", [item.operatorSelected]);
                  break;
              }

              if (item.errorMessage == null) {
                orArray.push({ column: heading, operator: operator, value: value });
              } else {
                item.operatorState = false;
              }
            });
            andArray.push(orArray);
          }
        });

      try {
        if (this.searchFormItems.some((item) => item.errorMessage != null)) {
          throw new Error(i18n.t("invalid_search_conditions"));
        }

        const conditions = { andArray: andArray, fps: this.fpsSelected };
        if (typeof window.api !== "undefined") {
          this.rtmriItems = await window.api.searchRtmriItems(conditions);
        } else {
          await axios.post(process.env.VUE_APP_RTMRIAPI_URL + "/search", conditions).then((response) => {
            this.rtmriItems = response.data;
          });
        }
        this.selectAllRowsBtn.disabled = this.rtmriItems.length == 0;
        this.clearSelectedBtn.disabled = this.rtmriItems.length == 0;
        this.showSearchAlert(this.rtmriItems.length > 0 ? "success" : "warning", i18n.tc("number_of_search_results", this.rtmriItems.length));
      } catch (e) {
        this.showSearchAlert("warning", e.message);
      }
    },
    showSearchAlert(variant, message) {
      this.searchAlert.show = true;
      this.searchAlert.variant = variant;
      this.searchAlert.message = message;
    },
    onRowSelected(items) {
      this.rtmriItemsSelected = items;
    },
    selectAllRows() {
      this.$refs.searchResultTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.searchResultTable.clearSelected();
    },
    moveRowUp(indexOnTable) {
      const index = this.perPage * (this.currentPage - 1) + indexOnTable;
      if (index > 0) {
        this.rtmriItems.splice(index - 1, 2, this.rtmriItems[index], this.rtmriItems[index - 1]);
      }
    },
    moveRowDown(indexOnTable) {
      const index = this.perPage * (this.currentPage - 1) + indexOnTable;
      if (index < this.rtmriItems.length - 1) {
        this.rtmriItems.splice(index, 2, this.rtmriItems[index + 1], this.rtmriItems[index]);
      }
    },
    async showPlaybackModal() {
      this.playbackAlert.show = false;

      try {
        this.playback.marginState = null;
        if (this.playback.margin < 0 || this.playback.margin > 0.5) {
          this.playback.marginState = false;
          // alert メッセージには検索結果の件数が表示されているので上書きしないよう、入力欄の状態だけ変更して戻る。
          return;
        }

        const conditions = {
          rtmriItemsSelected: this.rtmriItemsSelected,
          margin: Number(this.playback.margin),
          withSubtitles: this.playback.withSubtitles,
          withDentition: this.playback.withDentition,
        };
        if (typeof window.api !== "undefined") {
          const binary = await window.api.concatCutVideo(conditions);
          this.playback.videoSrc = "data:video/mp4;base64," + new Buffer(binary).toString("base64");
        } else {
          await axios.post(process.env.VUE_APP_RTMRIAPI_URL + "/concat", conditions).then((response) => {
            this.playback.videoSrc = "data:video/mp4;base64," + response.data;
          });
        }
        this.$bvModal.show("playback-modal");
      } catch (e) {
        if (e.response) {
          this.showPlaybackAlert("warning", e.message + ". " + e.response.data.detail);
        } else {
          this.showPlaybackAlert("warning", e.message);
        }
      }
    },
    showPlaybackAlert(variant, message) {
      this.playbackAlert.show = true;
      this.playbackAlert.variant = variant;
      this.playbackAlert.message = message;
    },
    prevFrame() {
      const video = document.getElementById("video");
      const fps = this.rtmriItemsSelected[0].fps;
      video.pause();
      if (fps === 14) {
        video.currentTime = Math.max(0, video.currentTime - 1 / 13.79);
      } else {
        video.currentTime = Math.max(0, video.currentTime - 1 / 27.17);
      }
    },
    nextFrame() {
      const video = document.getElementById("video");
      const fps = this.rtmriItemsSelected[0].fps;
      video.pause();
      if (fps === 14) {
        video.currentTime = Math.max(0, video.currentTime + 1 / 13.79);
      } else {
        video.currentTime = Math.max(0, video.currentTime + 1 / 27.17);
      }
    },
  },
};

function uniq(value, index, self) {
  return self.indexOf(value) === index;
}
</script>
