<template>
  <div id="app" class="d-flex flex-column h-100">
    <header>
      <Navbar />
    </header>
    <main role="main" class="flex-shrink-0">
      <SearchContainer />
      <SettingsModal ref="settingsModal" />
    </main>
    <footer class="footer mt-auto py-3">
      <div class="container text-center">
        <span class="text-muted">Copyright © Kikuo Maekawa (NINJAL) 2020</span>
      </div>
    </footer>
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import SearchContainer from "./components/SearchContainer.vue";
import SettingsModal from "./components/SettingsModal.vue";

export default {
  name: "App",
  components: {
    Navbar,
    SearchContainer,
    SettingsModal,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}
.footer {
  background-color: #f5f5f5;
}
.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}
</style>
