<template>
  <b-navbar type="dark" variant="dark" fixed="top">
    <b-navbar-brand>
      rtMRIDB (The real-time MRI articulatory movement database)
    </b-navbar-brand>
    <b-navbar-nav class="ml-auto">
      <div class="locale-changer">
        <b-select v-model="$i18n.locale" size="sm">
          <b-select-option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
            {{ $t("locale." + lang) }}
          </b-select-option>
        </b-select>
      </div>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
export default {
  name: "locale-changer",
  data() {
    return { langs: ["en", "ja"] };
  },
};
</script>
