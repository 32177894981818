<template>
  <b-modal id="settings-modal" :title="$t('label.settings')" size="lg" hide-header-close hide-footer no-close-on-backdrop no-close-on-esc>
    <b-alert :show="alert.show" :variant="alert.variant">{{ alert.message }}</b-alert>

    <b-form @submit="onSubmit">
      <b-form-group :label="$t('label.video_dir')" label-for="video-dir-name">
        <b-input-group>
          <b-input id="video-dir-name" v-model="settings.videoDirName" @update="hideAlert" required></b-input>
          <b-input-group-append>
            <b-btn @click="clickVideoDir" variant="primary"><b-icon icon="folder"></b-icon></b-btn>
          </b-input-group-append>
        </b-input-group>
        <input type="file" ref="videoDir" v-show="false" @input="onVideoDirInput" webkitdirectory />
      </b-form-group>
      <b-form-group :label="$t('label.video_dir_with_dentition')" label-for="video-dir-with-dentition">
        <b-input-group>
          <b-input id="video-dir-with-dentition" v-model="settings.videoDirWithDentitionName" @update="hideAlert" required></b-input>
          <b-input-group-append>
            <b-btn @click="clickVideoDirWithDentition" variant="primary"><b-icon icon="folder"></b-icon></b-btn>
          </b-input-group-append>
        </b-input-group>
        <input type="file" ref="videoDirWithDentition" v-show="false" @input="onVideoDirWithDentitionInput" webkitdirectory />
      </b-form-group>
      <b-btn class="mr-3" type="submit" variant="primary">{{ $t("action.save") }}</b-btn>
      <b-btn @click="hide">{{ $t("action.close") }}</b-btn>
    </b-form>
  </b-modal>
</template>

<script>
import i18n from "../i18n";

const path = require("path");

export default {
  data() {
    return {
      settings: {},
      alert: { show: false, variant: null, message: null },
    };
  },
  methods: {
    show(settings) {
      settings.videoDirName = settings.videoDirName ?? null;
      settings.videoDirWithDentitionName = settings.videoDirWithDentitionName ?? null;
      this.settings = settings;
      this.alert.show = false;
      this.$bvModal.show("settings-modal");
    },
    hide() {
      this.$bvModal.hide("settings-modal");
    },
    hideAlert() {
      this.alert.show = false;
    },
    clickVideoDir() {
      this.alert.show = false;
      this.$refs.videoDir.click();
    },
    onVideoDirInput() {
      const files = this.$refs.videoDir.files;
      if (files.length > 0) {
        this.settings.videoDirName = dirname(files[0].path);
      }
    },
    clickVideoDirWithDentition() {
      this.alert.show = false;
      this.$refs.videoDirWithDentition.click();
    },
    onVideoDirWithDentitionInput() {
      const files = this.$refs.videoDirWithDentition.files;
      if (files.length > 0) {
        this.settings.videoDirWithDentitionName = dirname(files[0].path);
      }
    },
    async onSubmit(event) {
      event.preventDefault();
      try {
        await window.api.saveSettings(this.settings);
        this.showAlert("success", i18n.t("settings_were_saved"));
      } catch (e) {
        this.showAlert("warning", e.message);
      }
    },
    showAlert(variant, message) {
      this.alert.show = true;
      this.alert.variant = variant;
      this.alert.message = message;
    },
  },
};

function dirname(file) {
  // バックスラッシュを置換せずに dirname にかけると . になってしまう。
  return file != null ? path.dirname(file.replace(/\\/g, "/")) : null;
}
</script>
